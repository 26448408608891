<template>
    <div class="Projectcation Batchcontrol" style="margin-top:20px;">
        <div class="Project_select">
           <el-button type="primary" size="mini" @click="dialogVisible=true,addpc()">添加批次</el-button>
        </div>
        <el-table 
            :data="tableData" 
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column prop="year" label="项目批次"></el-table-column>
            <el-table-column prop="startTime" label="填报开始时间"></el-table-column>
            <el-table-column prop="endTime" label="填报截止时间"></el-table-column>
            <el-table-column label="操作" width="170px">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="comment(scope.row)">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="tcation_Popup">
            <el-dialog
                title="添加项目批次"
                :visible.sync="dialogVisible"
                width="650px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form ref="ruleForm" :model="ruleForm" label-width="100px">
                    <el-form-item label="项目批次">
                        <el-input v-model="ruleForm.year" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="填报截止时间">
                        <el-date-picker
                                v-model="ruleForm.tationof"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                @change="getDate(ruleForm.tationof)">
                            </el-date-picker>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers('ruleForm')">确 定</el-button>
                    <el-button size="small" @click="closei('ruleForm')">取 消</el-button>
                </span>
            </el-dialog>
            <el-dialog
                title="添加项目批次"
                :visible.sync="dialogVisibleForm"
                width="650px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form ref="Form" :model="Form" label-width="100px">
                    <el-form-item label="项目批次">
                        <el-input v-model="Form.year" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="填报截止时间">
                        <el-date-picker
                                v-model="Form.tationof"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                @change="getDates(Form.tationof)">
                            </el-date-picker>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="updatembers('Form')">确 定</el-button>
                    <el-button size="small" @click="closei('Form')">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            dialogVisible:false,
            dialogVisibleForm:false,
            Form:{
                year:'',
                startTime:'',
                endTime:'',
                tationof:'',
            },
            ruleForm:{
                year:'',
                startTime:'',
                endTime:'',
                tationof:[],
            },
            tableData:[],
            rules:{
                opinion:[
                    { required: true, message: '请输入意见内容', trigger: 'blur' }
                ],
            },
            departList:[],
            depart:'',
        }
    },
    methods: {
        getapp(){
            this.axios.batch({
                params:{
                    sId:this.$store.state.schId,
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
            
        },
        // 填写学校推荐意见
        addmbers(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.sId=this.$store.state.schId,
                    this.axios.batchAdd(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisible = false
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }
                    }).catch(err=>{

                    })
                }else{

                }
            })
        },
        // 修改
        updatembers(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.batchEdit(
                        {
                            id:this.Form.id,
                            startTime:this.Form.startTime,
                            endTime:this.Form.endTime,
                        }
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisibleForm = false
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }
                    }).catch(err=>{

                    })
                }else{

                }
            })
        },
        //选择日期
        getDate(val){
            this.ruleForm.startTime = val[0]
            this.ruleForm.endTime = val[1]
        },
        getDates(val){
            this.Form.startTime = val[0]
            this.Form.endTime = val[1]
        },
        addpc(){
            let year = new Date().getFullYear()
            this.ruleForm.year = year
        },
        handleClose(done){

        },
        //学校意见
        comment(rows){
            this.dialogVisibleForm = true
            this.Form.id = rows.id
            this.Form.year = rows.year
            this.Form.startTime = rows.startTime
            this.Form.endTime = rows.endTime
            var arr = []
            arr.push(rows.startTime,rows.endTime)
            this.Form.tationof = arr
        },
        //关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.dialogVisibleForm =false
            this.$refs[formName].resetFields();
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tcation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tcation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tcation_Popup .el-dialog__footer{
    text-align: center;
}
.tcation_Popup .el-form-item__content{
    width: 350px;
}
.tcation_Popup .el-dialog__body{
    padding: 30px 50px;
}
.tcation_Popup .el-textarea__inner{
    resize: none;
    width: 450px;
}
.Projectcation.Batchcontrol .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation.Batchcontrol .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
</style>